




























































































import Vue from "vue";
import { DateTime } from "luxon";
import { formatDateToLocale, formatDate } from "@/utils/helpers";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { SETTINGS } from "../../../routes/endpoints";

const businessSector = [
    "Financial services",
    "Logistics",
    "Healthcare",
    "Human resources",
    "Information & communication Technology",
    "Manufacturing",
    "Marketing",
    "Media and entertainment",
    "Mining",
    "Oil and gas",
    "Private sector",
    "Professional services",
    "Real estate",
    "Telecommunication",
    "Trade",
    "Tourism",
    "Transportation",
    "Waste management",
    "Venture capital",
    "Others",
];

const companyStructure = [
    "Private Company Limited by Shares",
    "Public Company Limited by Shares",
    "Company Limited by Guarantee",
    "Unlimited Company",
    "Limited Liability Partnership",
    "Limited Partnership",
    "Business Name",
    "Incorporated Trustee",
    "Ministry, Department, or Agency of Federal Government of Nigeria",
    "Others",
];

export default Vue.extend({
    name: "EditProfile",
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
            profileData: "auth/getUserData"
        }),
        companyLogoId(): string {
            if (this.companyData && this.companyData.companyProfile) {
                const { logoImgUrl } = this.companyData.companyProfile;
                return logoImgUrl;
            }
            return "";
        },
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
    },
    watch: {
        companyData(val) {
            if (val.companyProfile) {
                this.profile = { ...val.companyProfile };
            }
        },
        editDetails(val) {
            this.$emit("toggleCompanyEdit", val);
        },
    },
    data() {
        return {
            loading: false,
            editDetails: false,
            dropDowns: {
                businessSector,
                companyStructure,
            },
            formFields: [
                {
                    key: "name",
                    label: "Regulator name",
                    type: "text",
                    isRequired: true,
                },
                {
                    key: "sectorRegulating",
                    label: "Sector regulating",
                    type: "text",
                    isRequired: true,
                },
                {
                    key: "website",
                    label: "Website",
                    type: "text",
                    isRequired: true,
                },
                {
                    key: "address",
                    label: "Address",
                    type: "textarea",
                    isRequired: true,
                },
            ],
            oldData: {
                logoImgUrl: null,
                motto: null,
                brandColor: null,
                licenseNumber: null,
            },
            profile: {
                name: "",
                website: "",
                sectorRegulating: "",
                address: "",
            },
        };
    },
    validations(): any {
        return {
            profile: {
                name: {
                    required,
                },
                businessSector: {
                    required,
                },
                website: {},
                primaryAddress: {
                    required,
                },
                rcNumber: {
                    required,
                },
                serviceDescription: {
                    required,
                },
                incorporationDate: {
                    required,
                },
                organisationStructure: {
                    required,
                },
            },
        };
    },
    methods: {
        ...mapActions({
            updateCompanyProfile: "company/updateProfile",
            fetchCompanyProfile: "company/fetchProfile",
            fetchProfile: "auth/fetchProfile",
        }),
        ...mapActions("company", ["setCompanyState"]),
        formatDateToLocale,
        formatDate,
        toggleEditDetails() {
            this.editDetails = !this.editDetails;
        },
        async goToNextStep() {
            this.loading = true;
            try {
                await this.fetchCompanyProfile(this.profileData.regulatorId);
                await this.fetchProfile();
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to fetch profile! Try again",
                    variant: "danger",
                    solid: true,
                });
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        async saveDetails() {
            this.loading = true;
            const prefix = "https://"
            const website = this.profile.website.includes(prefix) ? this.profile.website : `https://${this.profile.website}`
            const payload = { ...this.profile, logoIconId: this.companyData?.logoIconId, website };
            try {
                await this.updateCompanyProfile(payload).then(async (res) => {
                    this.$bvToast.toast(res.data.message, {
                        title: "Profile Update Successful!",
                        variant: "success",
                        solid: true,
                    });
                    try {
                        await this.goToNextStep();
                    } catch (e) {
                        this.$bvToast.toast("Error", {
                            title: "Unable to fetch business profile! Try again",
                            variant: "danger",
                            solid: true,
                        });
                    }
                    this.editDetails = false;
                });
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to update business profile!",
                    variant: "danger",
                    solid: true,
                });
            }
            this.loading = false;
        },
        setCompanyProfile() {
            this.profile = {
                ...this.profile,
                ...this.companyData,
            };
        },
    },

    mounted() {
        this.setCompanyProfile();
    },
});
